<template>
  <section id="visitors-donut">
    <card icon="chart-area"
          :headline="$t('visitors.channels.headline')"
          :description="$t('visitors.channels.description')"
    >
    </card>
  </section>
</template>

<script>
  export default {
    name: "VisitorsDonut"
  }
</script>

<style scoped lang="scss">

</style>
